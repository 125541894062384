/* AVATAR */
.avatar {
	width: 36px;
	height: 36px;
	background: #ddd;
	display: inline-block;
	border-radius: 36px;
	line-height: 36px;
	text-align: center;
	font-size: 16px;
	font-weight: 600;
	vertical-align: bottom;
	box-sizing: content-box;

	&.is-inactive {
		opacity: 0.5 !important;
	}

	&.is-pm {
		border: 3px solid rgba(var(--bs-info-rgb), 1) !important;
	}

	&.show-label {
		position: relative;
	}

	&.is-pm.show-label::after,
	&.is-inactive.show-label::after {
		position: absolute;
		left: 50%;
		bottom: -7px;
		width: 80%;
		height: 13px;
		font-size: 10px;
		border-radius: 0.4rem;
		line-height: 12px;
		margin-left: -40%;
	}

	&.is-pm.show-label::after {
		color: #fff;
		content: "PM";
		background-color: rgba(var(--bs-info-rgb), 1);
	}

	&.is-inactive.show-label::after {
		color: #607d8b;
		content: "EX";
		background-color: #e6e6e6;
	}

	&.avatar-icon {
		line-height: 34px !important;
	}
}

.nav-link>.avatar {
	vertical-align: middle;
}

.avatar.avatar-xs,
.avatar-bg.avatar-xs .avatar {
	width: 18px;
	height: 18px;
	border-radius: 18px;
	line-height: 18px;
	font-size: 8px;

	&.avatar-icon {
		line-height: 16px !important;
	}
}

.avatar.avatar-sm,
.avatar-bg.avatar-sm .avatar {
	width: 24px;
	height: 24px;
	border-radius: 24px;
	line-height: 24px;
	font-size: 8px;

	&.avatar-icon {
		line-height: 22px !important;
	}
}

.avatar.avatar-md,
.avatar-bg.avatar-md .avatar {
	width: 48px;
	height: 48px;
	border-radius: 48px;
	line-height: 48px;
	font-size: 18px;

	&.avatar-icon {
		line-height: 45px !important;
	}
}

.avatar.avatar-lg,
.avatar-bg.avatar-lg .avatar {
	width: 64px;
	height: 64px;
	border-radius: 64px;
	line-height: 63px;
	font-size: 32px;

	&.avatar-icon {
		line-height: 54px !important;
	}
}

.avatar.avatar-xl,
.avatar-bg.avatar-xl .avatar {
	width: 128px;
	height: 128px;
	border-radius: 128px;
	line-height: 127px;
	font-size: 64px;

	&.avatar-icon {
		line-height: 108px !important;
	}
}

.avatar-label {
	position: absolute;
	bottom: -12px;
	left: 1px;
	right: 1px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	border-radius: 0.5rem;
	font-size: 0.6rem;
	padding: 2px;
	text-align: center;
	user-select: none !important;
}

.avatar-bg {
	display: inline-block;
	padding: 0;
	margin: 0 0.25rem !important;
	border-radius: 50% !important;
	background-color: rgba(var(--bs-white-rgb), 1) !important;

	& .avatar {
		margin: 0 !important;
	}
}

.avatars-group {
	display: flex !important;
	flex-wrap: nowrap !important;
	padding-left: 18px;

	& .show-label::after {
		display: none !important;
	}
}

.avatars-group .avatar-bg,
.avatars-group span:not(.avatar-bg) .avatar,
.avatars-group span:not(.avatar-bg) .avatar:not(:first-child) {
	margin-left: -18px !important;
}

.avatars-group .avatar {
	border: 2px solid #fff;
}

.avatars-group span:not(.avatar-bg) .avatar,
.avatars-group span:not(.avatar-bg) .avatar:not(:first-child) {
	--bs-opacity-avatar: 1 !important;
	color: $white !important;
}

.avatars-group>div:hover {
	z-index: 10 !important;
}

.avatar.border {
	box-sizing: content-box;
}

.avatar-subicon {
	position: absolute;
	bottom: -4px;
	right: 0px;
	width: 16px;
	height: 16px;
	text-align: center;
	vertical-align: middle;
	line-height: 1;
	border-radius: 16px;
}

/* AVATAR COLORATI IN BASE ALLE INIZIALI */
$alphabet: (
	"A",
	"B",
	"C",
	"D",
	"E",
	"F",
	"G",
	"H",
	"I",
	"J",
	"K",
	"L",
	"M",
	"N",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"U",
	"V",
	"W",
	"X",
	"Y",
	"Z",
	"0",
	"1",
	"2",
	"3",
	"4",
	"5",
	"6",
	"7",
	"8",
	"9"
);

@each $char in $alphabet {
	$i: index($alphabet, $char);
	$col: nth(nth($colors-rgb, random(length($colors-rgb))), 1);

	.avatar[title^="#{$char}" i]:not(.avatar-icon),
	.avatar[alt^="#{$char}" i]:not(.avatar-icon) {
		--bs-opacity-avatar: 0.25;
		background: rgba(var(--bs-#{$col}-rgb),
			var(--bs-opacity-avatar)) !important;
		color: rgba(var(--bs-#{$col}-rgb), 1);
	}
}