// @import "bootstrap/scss/_variables.scss";
@import "./../../assets/variables.scss";

$particleSize: 20vmin;
$animationDuration: 6s;
$amount: 20;

.badge-jumbotron {
	position: relative;
	overflow: hidden;
	padding: 1rem;
	border-radius: var(--bs-border-radius);

	&>div:first-child {
		position: relative;
		z-index: 1;
	}

	&>div:last-child {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		width: 100%;
		height: 100%;
	}

	&.badge-jumbotron-success>div:last-child {
		background: lighten($green, 30%);
	}

	&.badge-jumbotron-warning>div:last-child {
		background: lighten($orange, 30%);
	}

	&.badge-jumbotron-secondary>div:last-child {
		background: lighten($grey, 30%);
	}

	&>div:last-child span {
		width: $particleSize;
		height: $particleSize;
		border-radius: $particleSize;
		backface-visibility: hidden;
		position: absolute;
		animation-name: move;
		animation-duration: $animationDuration;
		animation-timing-function: linear;
		animation-iteration-count: infinite;
	}
}


.badge-jumbotron.badge-jumbotron-success>div:last-child span {
	$colors: (
		lighten($green, 40%),
		lighten($green, 35%),
		lighten($green, 25%),
		lighten($green, 20%),
	);

@for $i from 1 through $amount {
	&:nth-child(#{$i}) {
		color: nth($colors, random(length($colors)));
		top: random(100) * 1%;
		left: random(100) * 1%;
		animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
		animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
		transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
		$blurRadius: (
			random() + 0.5) * $particleSize * 0.5;
		$x: if(random() > 0.5, -1, 1
		);
	box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
}
}
}

.badge-jumbotron.badge-jumbotron-warning>div:last-child span {
	$colors: (
		lighten($orange, 40%),
		lighten($orange, 35%),
		lighten($orange, 25%),
		lighten($orange, 20%),
	);

@for $i from 1 through $amount {
	&:nth-child(#{$i}) {
		color: nth($colors, random(length($colors)));
		top: random(100) * 1%;
		left: random(100) * 1%;
		animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
		animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
		transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
		$blurRadius: (
			random() + 0.5) * $particleSize * 0.5;
		$x: if(random() > 0.5, -1, 1
		);
	box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
}
}
}

.badge-jumbotron.badge-jumbotron-secondary>div:last-child span {
	$colors: (
		lighten($grey, 40%),
		lighten($grey, 35%),
		lighten($grey, 25%),
		lighten($grey, 20%),
	);

@for $i from 1 through $amount {
	&:nth-child(#{$i}) {
		color: nth($colors, random(length($colors)));
		top: random(100) * 1%;
		left: random(100) * 1%;
		animation-duration: (random($animationDuration * 10) / 10) * 1s + 10s;
		animation-delay: random(($animationDuration + 10s) * 10) / 10 * -1s;
		transform-origin: (random(50) - 25) * 1vw (random(50) - 25) * 1vh;
		$blurRadius: (
			random() + 0.5) * $particleSize * 0.5;
		$x: if(random() > 0.5, -1, 1
		);
	box-shadow: ($particleSize * 2 * $x) 0 $blurRadius currentColor;
}
}
}

@keyframes move {
	100% {
		transform: translate3d(0, 0, 1px) rotate(360deg);
	}
}