/* BOOTSTRAP */
@import './assets/variables.scss';

@import './assets/colors.scss';
@import './assets/avatar.scss';

@import 'bootstrap/scss/bootstrap.scss';

@import './assets/dark-theme.scss';

/* MDI ICONS */
@import './assets/materialdesignicons.scss';

/* RESIZEBLE */
@import './assets/resizable.scss';

/* SWIPERJS */
@import './assets/swiperjs.scss';

.App {
	text-align: center;
	text-align: center;
	display: flex;
	flex-direction: row;
	flex: 1 1 auto;
	height: 100vh;


	&>main {
		display: flex;
		flex-direction: column;
		flex: 1 1 auto;
		height: 100vh;
		// max-width: calc(100vw - var(--sidenav-width));
		overflow: hidden;
		transition: 0.3s all ease-in-out;

		&>*:has(#navbar-short)+&>header,
		&>footer {
			display: flex;
			position: initial;
			overflow: initial;
		}

		&>section {
			display: flex;
			flex-direction: column;
			flex: 1;
			overflow: auto;
			text-align: initial;
		}
	}
}

.modal-content {
	box-shadow: var(--bs-box-shadow-sm);
}

@include media-breakpoint-up(sm) {
	.modal-content {
		box-shadow: var(--bs-box-shadow);
	}

	.offcanvas-top {
		box-shadow: 0px 0.125rem 1rem rgba(0, 0, 0, 0.095);
	}

	.offcanvas-start {
		box-shadow: 0.125rem 0px 1rem rgba(0, 0, 0, 0.095);
	}

	.offcanvas-end {
		box-shadow: -0.125rem 0px 1rem rgba(0, 0, 0, 0.095);
	}

	.offcanvas-bottom {
		box-shadow: 0px -0.125rem 1rem rgba(0, 0, 0, 0.095);
	}
}

.modal-open .modal-backdrop,
.offcanvas-backdrop {
	backdrop-filter: blur(3px);
}

.cursor-pointer {
	cursor: pointer;
}

.cursor-grab {
	cursor: grab;
}

.cursor-loading {
	cursor: wait;
}

.cursor-text {
	cursor: text;
}

.text-justify {
	text-align: justify;
}

.dragging,
.dragging>* {
	cursor: grabbing !important;
	user-select: none;
	pointer-events: none;
}

[data-dismiss=dropdown] *,
[data-bs-offset-top] *,
[data-bs-toggle=collapse] *,
[data-bs-toggle=tooltip] *,
[data-bs-toggle=popover] * {
	pointer-events: none;
}

//fix per dropdown dentro il nav
nav .dropdown-menu {
	z-index: 10001;
}

.was-validated .has-validation:has(input:valid) .input-group-text,
.was-validated .has-validation:has(.is-valid) .input-group-text {
	border-color: var(--bs-form-valid-border-color);
}

.was-validated .has-validation:has(input:invalid) .input-group-text,
.was-validated .has-validation:has(.is-invalid) .input-group-text {
	border-color: var(--bs-form-invalid-border-color);
}


/*List */
.list-group-item:has(a.active) {
	z-index: 2;
	color: var(--bs-primary);
	background: rgba(var(--bs-primary-rgb), 0.1);
	border-color: transparent;
}

.list-group-item+.list-group-item:has(a.active) {
	margin-top: calc(-1* var(--bs-list-group-border-width));
	border-top-width: var(--bs-list-group-border-width);
}