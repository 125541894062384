$primary: #2196F3;
$success: #0f9d58;
$danger: #f44336;
$warning: #ff9800;
$info: #3498db;

$body-bg: #fafbfe;
$font-size-base: .8rem;

// colori extra
$white: #FFFFFF;
$blue-grey: #607D8B;
$brown: #795548;
$deep-orange: #FF5722;
$orange: #FF9800;
$amber: #FFC107;
$light-green: #8BC34A;
$green: #43A047;
$teal: #009688;
$cyan: #00BCD4;
$light-blue: #03A9F4;
$blue: #2196F3;
$purple: #9C27B0;
$indigo: #3F51B5;
$deep-purple: #673AB7;
$pink: #E91E63;
$red: #F44336;
$grey: #6c757d;
$light-grey: #9E9E9E;
$dark-grey: #343a40;

$colors: (
	"blue-grey": $blue-grey,
	"brown": $brown,
	"deep-orange": $deep-orange,
	"orange": $orange,
	"amber": $amber,
	"light-green": $light-green,
	"green": $green,
	"teal": $teal,
	"cyan": $cyan,
	"light-blue": $light-blue,
	"blue": $blue,
	"purple": $purple,
	"indigo": $indigo,
	"deep-purple": $deep-purple,
	"pink": $pink,
	"red": $red,
	"grey": $grey,
	"light-grey": $light-grey,
	"dark-grey": $dark-grey
);


$list-group-bg: $white;
$accordion-bg: $white;

$border-radius: .5rem;
$popover-border-radius: $border-radius;

$modal-content-border-color: rgba(0, 0, 0, 0.04);
$modal-backdrop-bg: rgba(255, 255, 255, 0.5);
$modal-backdrop-opacity: 1;

$modal-fade-transform: scale(.8);

//focus input
$focus-ring-width: .5rem;
$focus-ring-opacity: 0;
$focus-ring-blur: .25rem;