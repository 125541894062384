.clock {
	height: 100%;
	// color: white;
	// font-size: 22vh;
	// font-family: sans-serif;
	line-height: 1;
	display: flex;
	position: relative;
	/*background: green;*/
	overflow: hidden;

	justify-content: center;
	align-items: center;

	&::before,
	&::after {
		content: '';
		width: 100%;
		height: 15%;
		position: absolute;
		z-index: 2;
	}

	&::before {
		top: 0;
		background: linear-gradient(to top, transparent, #fff);
	}

	&::after {
		bottom: 0;
		background: linear-gradient(to bottom, transparent, #fff);
	}

	&>div {
		display: flex;
	}

	& .tick {
		line-height: 1;
	}

	& .tick-hidden {
		opacity: 0;
	}

}

.move {
	animation: move linear 1s infinite;
}

@keyframes move {
	from {
		transform: translateY(0%);
	}

	to {
		transform: translateY(-20%);
	}
}