.fc-view-harness {
	border-radius: var(--bs-border-radius);
	border: 1px solid var(--bs-border-color);
	overflow: hidden;

	& .fc-scrollgrid,
	& .fc-list {
		border: none !important;
	}

	& tr td:last-child,
	& tr th:last-child {
		border-right: 0 !important;
	}

	& tr:last-of-type td {
		border-bottom: 0 !important;
	}
}

.fc-header-toolbar {
	& .fc-toolbar-chunk {
		display: flex !important;
	}

	& .fc-toolbar-title {
		text-transform: capitalize !important;
	}

	& .fc-button {
		line-height: 1;
		box-shadow: none !important;
	}

	& .fc-button-primary {
		background-color: var(--bs-primary-border-subtle) !important;
		border-color: var(--bs-primary-border-subtle) !important;
		color: var(--bs-primary) !important;

		&:hover:not(:disabled) {
			background-color: var(--bs-primary) !important;
			border-color: var(--bs-primary) !important;
			color: var(--bs-white) !important;
		}
	}
}

.fc-col-header-cell {
	background: var(--bs-light) !important;
	padding: 0.5rem !important;
	color: var(--bs-border-color) !important;

	& a {
		color: var(--bs-body-color);
		text-decoration: none;
	}
}

.fc-daygrid-day-top a:not([href]),
.fc-list-day a:not([href]) {
	color: inherit;
	text-decoration: inherit;
}

.fc .fc-daygrid-day.fc-day-today {
	background-color: rgba(var(--bs-primary-rgb), .1) !important;
}

.fc .fc-daygrid-day.fc-day-sun,
.fc .fc-daygrid-day.fc-day-sat {
	background-color: rgba(var(--bs-secondary-bg-rgb), .2) !important;
}

.fc-daygrid-event {
	border: 0.5rem !important;
	padding: 0.5rem !important;
	margin-bottom: 3px;
}


.fc-daygrid-more-link {
	width: 100%;
	background: var(--bs-light);
	color: var(--bs-text-body);
	text-align: center !important;
	padding: 0.5rem !important;
	text-decoration: none !important;

	&:hover {
		background: var(--bs-light-hover);
	}
}

.fc .fc-popover {
	border-radius: var(--bs-border-radius);
	overflow: hidden;

	& .fc-popover-header {
		background: var(--bs-light) !important;
		padding: 0.5rem;
		border-bottom: 1px solid var(--bs-border-color);
	}
}

.fc-bg-event {
	--fc-bg-event-opacity: 0.15 !important;
	opacity: 1 !important;
}

.fc-list-event {
	background: transparent !important;
	color: var(--bs-text-body) !important;
}


.event-details {

	& .overlay {
		position: fixed;
		top: 0;
		bottom: 0;
		z-index: 1040;
		width: 100%;
		background-color: rgba(var(--bs-white-rgb), 0.5);
		left: 50%;
		transform: translateX(-50%);
		will-change: opacity;
		-webkit-backdrop-filter: blur(3px);
		backdrop-filter: blur(3px);
		cursor: pointer;
	}

	& .event-wrap {
		z-index: 1041;
		position: absolute;
		width: 50vw;
		cursor: auto;
		left: 25%;
		top: 25%;
		bottom: 25%;
		right: 25%;
		background: var(--bs-white);
		border-radius: var(--bs-border-radius);
		box-shadow: var(--bs-box-shadow);

		&.event-wrap-default {
			bottom: auto !important;
			right: auto !important;
		}

		//mobile
		@media (max-width: 768px) {
			width: 90vw;
			left: 5vw;
			right: 5vw;
			top: 15vh;
			bottom: 15vh;
		}
	}
}