[data-bs-theme=dark] {

	--bs-body-bg: #0f1318;
	--bs-body-bg-rgb: 15, 19, 24;
	--bs-white: #212529;
	--bs-white-rgb: 33, 37, 41;
	--bs-light: var(--bs-dark);
	--bs-light-rgb: var(--bs-dark-rgb);
	--bs-black: #dddddd;
	--bs-black-rgb: 221, 221, 221;

	--bs-border-color: #2c3136;

	.btn-dark {
		--bs-btn-bg: #0e0f10;
	}

	.btn-light {
		--bs-btn-color: #FFFFFF !important;
		--bs-btn-bg: #212529 !important;
		--bs-btn-border-color: #212529 !important;
		--bs-btn-hover-color: #FFFFFF !important;
		--bs-btn-hover-bg: #424649 !important;
		--bs-btn-hover-border-color: #373b3e !important;
		--bs-btn-focus-shadow-rgb: 66, 70, 73 !important;
		--bs-btn-active-color: #FFFFFF !important;
		--bs-btn-active-bg: #4d5154 !important;
		--bs-btn-active-border-color: #373b3e !important;
		--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125) !important;
		--bs-btn-disabled-color: #FFFFFF !important;
		--bs-btn-disabled-bg: #212529 !important;
		--bs-btn-disabled-border-color: #212529 !important;
	}

	.list-group {
		--bs-list-group-bg: var(--bs-white);
		--bs-list-group-disabled-bg: var(--bs-white);
	}

	.accordion {
		--bs-accordion-bg: var(--bs-body) !important;
	}

	.popover {
		--bs-popover-header-bg: var(--bs-dark) !important;
	}

	.modal-open .modal-backdrop,
	.offcanvas-backdrop {
		background: rgba(0, 0, 0, 0.15) !important;
	}
}