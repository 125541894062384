.item {

	nav {
		padding-bottom: 0.5rem !important;
	}

	.nav {
		border-radius: var(--bs-border-radius);
	}

	.nav-link {
		--bs-text-opacity: 1;
		color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
		border: 0 !important;
		border-radius: var(--bs-border-radius);
		transition: all 0.25s ease-in-out;

		&.active,
		&:hover {
			--bs-bg-opacity: 1;
			background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
			box-shadow: var(--bs-box-shadow-sm);
		}
	}
}