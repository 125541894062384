@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

$white-color: (
	"white": $white
);
$theme-colors: map-merge($theme-colors, $white-color);
$theme-colors: map-merge($theme-colors, $colors);

@import "bootstrap/scss/maps";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/mixins";

$theme-colors-rgb: map-loop($theme-colors, to-rgb, "$value");
$utilities-colors: map-merge($utilities-colors, $theme-colors-rgb);
$utilities-text-colors: map-loop($utilities-colors, rgba-css-var, "$key", "text");
$utilities-bg-colors: map-loop($utilities-colors, rgba-css-var, "$key", "bg");
$colors-rgb: map-loop($colors, to-rgb, "$value") !default;

@each $name, $values in $colors {
	.bg-#{$name}-subtle {
		background-color: mix($values, #fff, 50%) !important;
	}
}