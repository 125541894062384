@import 'swiper/css';
@import 'swiper/css/navigation';


.swiper-button-prev,
.swiper-button-next {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 3rem;
	height: 100%;
	margin: 0;
	z-index: 10;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 1;
	}
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
	right: -0.25rem;
	left: auto;
	background: linear-gradient(270deg, var(--bs-body-bg), transparent);
	border-radius: 0 0.5rem 0.5rem 0;

	&:after {
		right: -3rem;
		transition: all .15s ease-in-out;
	}

	&:hover {
		&:after {
			right: 0;
			background: linear-gradient(270deg, var(--bs-gray-400), transparent);
		}
	}
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
	right: auto;
	left: -0.25rem;
	background: linear-gradient(90deg, var(--bs-body-bg), transparent);
	border-radius: 0.5rem 0 0 0.5rem;

	&:after {
		left: -3rem;
		transition: all .15s ease-in-out;
	}

	&:hover {
		&:after {
			left: 0;
			background: linear-gradient(90deg, var(--bs-gray-400), transparent);
		}
	}
}

/*
& .tns-controls button {
	position: absolute;
	top: 0;
	min-width: 20px;
	height: 100%;
	background: rgba(0, 0, 0, .3);
	border: 0;
	outline: 0;
	opacity: .7;
	opacity: .7;
	z-index: 1;
	text-align: center;
	display: block;
	padding: 2px;

	&[data-controls="prev"] {
		background: rgb(0, 0, 0);
		background: linear-gradient(90deg, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 100%);
		left: 0px;
		border-radius: var(--bs-border-radius);
	}

	&[data-controls="next"] {
		background: rgb(0, 0, 0);
		background: linear-gradient(-90deg, rgba(0, 0, 0, .12) 0%, rgba(0, 0, 0, 0) 100%);
		right: 0px;
		border-radius: var(--bs-border-radius);
	}

	&:hover {
		opacity: 1;
	}
}
*/