$particles: 62;
$particleSize: 8px;
$radius: 80;
$lapDuration: 6s;

.dashboard-wrapper {
	position: relative;
	min-height: 140px;
	background-color: var(--bs-primary);
	overflow: hidden;
	border-radius: 0.5rem;

	&:before {
		content: ' ';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 0.25;
		background: url("~/public/images/secure/header.jpg") center center no-repeat;
		background-size: cover;
		z-index: 1;
	}

	& .dashboard-content {
		position: absolute;
		z-index: 3;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		justify-content: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		color: var(--bs-white);
		text-shadow: 0 0 20px rgba(0, 0, 0, 0.35);
	}

	& .dashboard-background {
		position: absolute;
		top: -50%;
		left: 0;
		z-index: 2;
		width: 100%;
		height: 100%;

	}

}

@keyframes spin {
	from {
		opacity: 0.0;
	}

	to {
		opacity: 0.6;
		transform: translate3d(-$particleSize/2, -$particleSize/2, 570px);
	}
}