@import "./variables.scss";

$datepicker__background-color: $primary !default;
// $datepicker__selected-color: $primary !default;
$datepicker__header-color: $white !default;
$datepicker__border-radius: 0.5rem !default;
$datepicker__font-family: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

@import "react-datepicker/src/stylesheets/datepicker.scss";

.react-datepicker-wrapper {
	width: 100%;
}

.react-datepicker-popper {
	z-index: 3;
}

.react-datepicker {
	border: 0;
	box-shadow: var(--bs-box-shadow);
}

.react-datepicker__day-name {
	color: $white;
}

.react-datepicker__header__dropdown {
	padding: 6px;
}

.react-datepicker__month-select,
.react-datepicker__year-select {
	padding: 2px;
	border-radius: 0.5rem;
	border-color: rgba(0, 0, 0, .2);
	background: rgba(0, 0, 0, .09);
	color: $white;
}

.react-datepicker__day,
.react-datepicker__week-number {
	border-radius: 100px !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
	background: $primary !important;
	color: $white;
}

.react-datepicker__year-select option,
.react-datepicker__month-select option {
	background: $primary !important;
	color: $white;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__navigation-icon::before {
	border-color: $white !important;
}

.react-datepicker__month.react-datepicker__weekPicker {
	position: relative;
}

.react-datepicker__month.react-datepicker__weekPicker:before {
	content: '';
	width: 32px;
	height: 100%;
	background: #0000000f;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}

.react-datepicker__week {
	position: relative;
	z-index: 1;
}