@import "./variables.scss";

.ql-toolbar.ql-snow {
	border-radius: var(--bs-border-radius) var(--bs-border-radius) 0 0;
}

.ql-container.ql-snow {
	border-radius: 0 0 var(--bs-border-radius) var(--bs-border-radius);
}

.ql-toolbar.ql-snow,
.ql-container.ql-snow {
	border: var(--bs-border-width) solid var(--bs-border-color);
}

.was-validated .is-invalid .ql-toolbar.ql-snow,
.was-validated .is-invalid .ql-container.ql-snow {
	border: var(--bs-border-width) solid var(--bs-form-invalid-border-color);
	color: var(--bs-form-invalid-color);
}

.was-validated .is-valid .ql-toolbar.ql-snow,
.was-validated .is-valid .ql-container.ql-snow {
	border: var(--bs-border-width) solid var(--bs-form-valid-border-color);
	color: var(--bs-form-valid-color);
}

.ql-container.ql-snow:has(.ql-editor:focus) {
	border: var(--bs-border-width) solid rgba(var(--bs-primary-rgb), .5);
}

.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
	height: 20px;
	width: 24px;
}