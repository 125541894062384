header nav.navbar {

	& .nav-item>button,
	& .dropdown>button {
		margin: 2px;
		transition: all .25s ease-in-out;
		border: none;

		&>* {
			transition: all .25s ease-in-out;
		}

		&:hover {

			&>*,
			& i:before {
				transform: scale(1.2);
			}
		}

		&:active,
		&:focus,
		&:hover {
			background-color: rgba(var(--bs-secondary-rgb), .2);
			border: none;
		}
	}
}

footer nav.navbar .col {

	&>a,
	&>button {
		margin: 2px;
		transition: all .25s ease-in-out;
		border: none;

		&>* {
			transition: all .25s ease-in-out;
		}

		&:hover {

			&>*,
			& i:before {
				transform: scale(1.2);
			}
		}

		&:active,
		&:focus,
		&:hover {
			background-color: rgba(var(--bs-secondary-rgb), .2);
			border: none;
		}
	}
}

.notification-badge {
	position: relative;

	&:after {
		content: '';
		position: absolute;
		width: 6px;
		height: 6px;
		background: #4CAF50;
		bottom: 2px;
		right: 0px;
		border-radius: 6px;
		box-shadow: 0 0 3px #ffffff;
	}
}