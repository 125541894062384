.ganttTable {
	display: table;
	border-bottom: #e6e4e4 1px solid;
	border-top: #e6e4e4 1px solid;
	border-left: #e6e4e4 1px solid;
}

.ganttTable_Header {
	display: table-row;
	list-style: none;
}

.ganttTable_HeaderSeparator {
	border-right: 1px solid rgb(196, 196, 196);
	opacity: 1;
	margin-left: -2px;
}

.ganttTable_HeaderItem {
	display: table-cell;
	vertical-align: -webkit-baseline-middle;
	vertical-align: middle;
}

.taskListWrapper {
	display: table;
	border-bottom: #e6e4e4 1px solid;
	border-left: #e6e4e4 1px solid;
}

.taskListTableRow {
	display: table-row;
	text-overflow: ellipsis;
}

.taskListTableRow:nth-of-type(even) {
	background-color: #f5f5f5;
}

.taskListCell {
	display: table-cell;
	vertical-align: middle;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.taskListNameWrapper {
	display: flex;
}

.taskListExpander {
	color: rgb(86 86 86);
	font-size: 0.6rem;
	padding: 0.15rem 0.2rem 0rem 0.2rem;
	user-select: none;
	cursor: pointer;
}

.taskListEmptyExpander {
	font-size: 0.6rem;
	padding-left: 1rem;
	user-select: none;
}

.ViewContainer {
	list-style: none;
	-ms-box-orient: horizontal;
	display: flex;
	-webkit-justify-content: flex-end;
	justify-content: flex-end;
	align-items: center;
}












._3_ygE {
	display: table;
	border-bottom: #e6e4e4 1px solid;
	border-top: #e6e4e4 1px solid;
	border-left: #e6e4e4 1px solid;
}

._1nBOt {
	display: table-row;
	list-style: none;
}

._2eZzQ {
	border-right: 1px solid rgb(196, 196, 196);
	opacity: 1;
	margin-left: -2px;
}

._WuQ0f {
	display: table-cell;
	vertical-align: -webkit-baseline-middle;
	vertical-align: middle;
}

._3ZbQT {
	display: table;
	border-bottom: #e6e4e4 1px solid;
	border-left: #e6e4e4 1px solid;
}

._34SS0 {
	display: table-row;
	text-overflow: ellipsis;
}

._34SS0:nth-of-type(even) {
	background-color: #f5f5f5;
}

._3lLk3 {
	display: table-cell;
	vertical-align: middle;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

._nI1Xw {
	display: flex;
}

._2QjE6 {
	color: rgb(86 86 86);
	font-size: 0.6rem;
	padding: 0.15rem 0.2rem 0rem 0.2rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	cursor: pointer;
}

._2TfEi {
	font-size: 0.6rem;
	padding-left: 1rem;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

._3T42e {
	background: #fff;
	padding: 12px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

._29NTg {
	font-size: 12px;
	margin-bottom: 6px;
	color: #666;
}

._25P-K {
	position: absolute;
	display: flex;
	flex-shrink: 0;
	pointer-events: none;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

._3gVAq {
	visibility: hidden;
	position: absolute;
	display: flex;
	pointer-events: none;
}

._1eT-t {
	overflow: hidden auto;
	width: 1rem;
	flex-shrink: 0;
	/*firefox*/
	scrollbar-width: thin;
}

._1eT-t::-webkit-scrollbar {
	width: 1.1rem;
	height: 1.1rem;
}

._1eT-t::-webkit-scrollbar-corner {
	background: transparent;
}

._1eT-t::-webkit-scrollbar-thumb {
	border: 6px solid transparent;
	background: rgba(0, 0, 0, 0.2);
	background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
	border-radius: 10px;
	background-clip: padding-box;
}

._1eT-t::-webkit-scrollbar-thumb:hover {
	border: 4px solid transparent;
	background: rgba(0, 0, 0, 0.3);
	background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
	background-clip: padding-box;
}

._2dZTy {
	fill: #fff;
}

._2dZTy:nth-child(even) {
	fill: #f5f5f5;
}

._3rUKi {
	stroke: #ebeff2;
}

._RuwuK {
	stroke: #e6e4e4;
}

._9w8d5 {
	text-anchor: middle;
	fill: #333;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

._1rLuZ {
	stroke: #e6e4e4;
}

._2q1Kt {
	text-anchor: middle;
	fill: #555;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

._35nLX {
	fill: #ffffff;
	stroke: #e0e0e0;
	stroke-width: 1.4;
}

._KxSXS {
	cursor: pointer;
	outline: none;
}

._KxSXS:hover ._3w_5u {
	visibility: visible;
	opacity: 1;
}

._3w_5u {
	fill: #ddd;
	cursor: ew-resize;
	opacity: 0;
	visibility: hidden;
}

._31ERP {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	stroke-width: 0;
}

._RRr13 {
	cursor: pointer;
	outline: none;
}

._2P2B1 {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

._1KJ6x {
	cursor: pointer;
	outline: none;
}

._2RbVy {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	opacity: 0.6;
}

._2pZMF {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

._3zRJQ {
	fill: #fff;
	text-anchor: middle;
	font-weight: lighter;
	dominant-baseline: central;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

._3KcaM {
	fill: #555;
	text-anchor: start;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	pointer-events: none;
}

._CZjuD {
	overflow: hidden;
	font-size: 0;
	margin: 0;
	padding: 0;
}

._2B2zv {
	margin: 0;
	padding: 0;
	overflow: hidden;
}

._3eULf {
	display: flex;
	padding: 0;
	margin: 0;
	list-style: none;
	outline: none;
	position: relative;
}

._2k9Ys {
	overflow: auto;
	max-width: 100%;
	/*firefox*/
	scrollbar-width: thin;
	/*iPad*/
	height: 1.2rem;
}

._2k9Ys::-webkit-scrollbar {
	width: 1.1rem;
	height: 1.1rem;
}

._2k9Ys::-webkit-scrollbar-corner {
	background: transparent;
}

._2k9Ys::-webkit-scrollbar-thumb {
	border: 6px solid transparent;
	background: rgba(0, 0, 0, 0.2);
	background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
	border-radius: 10px;
	background-clip: padding-box;
}

._2k9Ys::-webkit-scrollbar-thumb:hover {
	border: 4px solid transparent;
	background: rgba(0, 0, 0, 0.3);
	background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
	background-clip: padding-box;
}

@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {}

._19jgW {
	height: 1px;
}