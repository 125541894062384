.resizable-bar {
	padding: 0 !important;
	height: 100%;
	right: -20px !important;

	&>div {
		width: 6px;
		height: 100%;
		background-color: var(--bs-secondary);
		border-radius: var(--bs-border-radius);
		opacity: 0.25;
	}

	&:hover>div {
		opacity: 0.5;
	}
}