#sidenav {
	min-height: 100vh;
	position: sticky;
	top: 0;
	bottom: 0;
	left: 0;
	background-color: var(--bs-white);
	box-shadow: var(--bs-box-shadow-sm) !important;
	display: flex;
	flex: 0 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	z-index: 1030;
	transition: 0.3s all ease-in-out;
	padding: 0;

	&.show,
	&:not(.navbar-short) {
		width: 260px;
		min-width: 260px;
		max-width: 260px;
	}

	&>div {
		width: 100%;
	}

	& .navbar-vertical-header {
		padding-top: .35rem;
		padding-bottom: .35rem;
		padding-left: .35rem;
		margin: 0;

		& .navbar-brand {
			width: 100%;
			display: flex;
			align-items: center;
			padding-left: 0.5rem;
			margin: 0;

			&>span {
				display: flex;
				flex-direction: column;
				text-align: start;
				padding-left: 6px;
				opacity: 1;
				visibility: visible;
				justify-content: start !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transition: all .25s ease-in-out;
			}
		}
	}

	& .navbar-vertical-footer {
		position: relative;
		display: block;
		padding: 0.15rem 0.35rem;
		padding-left: 0.75rem;
		margin: 0 0.35rem;

		&>button {
			transition: all .25s ease-in-out;
			padding-left: 1rem;

			& span {
				opacity: 1;
				visibility: visible;
				justify-content: start !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transition: all .25s ease-in-out;
			}

			&:hover {
				background-color: var(--bs-light);
			}
		}

		&>.dropdown {
			display: none;
		}
	}

	//mobile
	@media (max-width: 992px) {
		position: fixed !important;
		left: -260px;
		width: 260px;
		justify-content: stretch;

		&.show {
			left: 0;
		}

		& .navbar-vertical-footer>button {
			display: none !important;
		}

		& .navbar-vertical-footer>.dropdown {
			display: inline-flex;

			& .dropdown-menu[data-bs-popper] {
				top: auto;
				bottom: 100%;
				right: auto !important;
				margin-top: 0;
				margin-bottom: var(--bs-dropdown-spacer);
			}
		}

		&+.offcanvas-backdrop {
			display: block !important;
		}

		&.show+.offcanvas-backdrop {
			opacity: 1 !important;
			visibility: visible !important;
		}
	}

	//versione compressa (short)
	&.navbar-short {
		width: 4rem !important;

		//fix per main
		&+main {
			max-width: 100vw;
		}

		& .navbar-vertical-footer,
		& .navbar-vertical-header {
			padding-left: 0.35rem;

			& button span,
			& .navbar-brand span {
				width: 0;
				height: 0;
				opacity: 0;
				visibility: hidden;
			}

		}

		& .nav-item {

			& .nav-link {
				justify-content: start !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				& .i {
					transition: all .25s ease-in-out;
				}

				&:not(.collapsed)>i:first-of-type {
					transform: none;
				}

				&>i[data-chevron] {
					visibility: hidden;
					opacity: 0;
				}

				& span {
					width: 0;
					height: 0;
					opacity: 0;
					visibility: hidden;
					padding: 0;
					flex: none;
					transition: all .25s ease-in-out;
				}
			}

			&:hover .nav-link i[data-icon],
			&.active .nav-link i[data-icon],
			& .nav-link.active i[data-icon] {
				transform: scale(1.2);
			}

			//children
			& .nav-item {
				padding: 0.35rem;

				& .nav-link {
					justify-content: start !important;
					padding: 0.35rem;
					position: relative;
					padding-left: 0.85rem;

					&.active:before {
						position: absolute;
						content: '';
						width: 0.28rem;
						top: 0;
						left: 0;
						height: 100%;
						background-color: var(--bs-primary);
						border-radius: 0.5rem 0 0 0.5rem;
					}

					// & i {
					// 	transform: none !important;
					// }

					& span {
						width: auto;
						height: auto;
						opacity: 1;
						flex: 1 0;
						visibility: visible;
					}

				}
			}
		}
	}

	//versione estesa
	& .nav-item {
		position: relative;
		display: block;
		padding: 0.15rem 0.35rem;
		margin: 0 0.35rem;

		&:hover>.nav-link {
			background-color: rgba(var(--bs-secondary-rgb), .2);

			// & .nav-item {
			// 	background-color 
			// }
		}

		&.active .nav-link,
		& .nav-link.active {
			color: var(--bs-primary);
			background: rgba(var(--bs-primary-rgb), 0.1);
		}

		& .nav-link {
			border-radius: .5rem;
			width: 100%;
			transition: all .15s ease-in-out;
			background-color: transparent;
			display: flex;
			flex-direction: row;
			align-items: center !important;
			position: relative;
			justify-content: start !important;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			& span {
				flex: 1 0;
				text-align: start;
				padding-left: 1rem;
				white-space: normal !important;
				justify-content: start !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transition: all .25s ease-in-out;
			}

			&>i[data-chevron] {
				transition: transform .15s ease-in-out;
			}

			&:not(.collapsed)>i[data-chevron] {
				transform: rotate(90deg);
			}
		}

		//children
		& .nav-item {
			margin: 0;
		}
	}

	//backdrop
	&+.offcanvas-backdrop {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		opacity: 0;
		visibility: hidden;
		display: none;
		z-index: 1029;
		transition: 0.3s all ease-in-out;
	}
}

.sidenav-popover {
	--bs-popover-header-bg: var(--bs-light) !important;
	--bs-popover-header-color: var(--bs-body-color) !important;
	--bs-popover-body-padding-x: .35rem;
	--bs-popover-body-padding-y: .35rem;
	min-width: 200px;
	overflow: hidden;
	box-shadow: var(--bs-box-shadow-sm) !important;

	& .popover-header {
		& .nav-link {
			padding: 0.35rem;
			position: relative;
			border-radius: .5rem;
			width: 100%;
			transition: all .15s ease-in-out;
			background-color: transparent;
			display: flex;
			flex-direction: row;
			align-items: center !important;
			justify-content: start !important;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;

			& span {
				width: auto;
				height: auto;
				opacity: 1;
				flex: 1 0;
				visibility: visible;
				text-align: start;
				padding-left: 1rem;
				white-space: normal !important;
				justify-content: start !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				transition: all .25s ease-in-out;
			}
		}
	}

	& .popover-body {
		background-color: var(--bs-white);
		overflow: hidden;
		// border-radius: 0.5rem;
		padding: 0;
		// width: 200px;
		display: block !important;
		// box-shadow: var(--bs-box-shadow-sm) !important;

		&>ul {
			margin: 0;
			border-radius: .5rem;
			position: relative;
			padding-bottom: 8px;
		}

		&>ul:has(li:only-child) {
			padding-bottom: 0px !important;

			& li {
				border-bottom: 0 !important;
			}
		}

		//children
		& .nav-item {
			padding: 0.35rem;

			& .nav-link {
				padding: 0.35rem;
				position: relative;
				border-radius: .5rem;
				width: 100%;
				transition: all .15s ease-in-out;
				background-color: transparent;
				display: flex;
				flex-direction: row;
				align-items: center !important;
				justify-content: start !important;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;

				&:hover {
					background-color: var(--bs-light);
				}

				&.active {
					color: var(--bs-primary);
					background: rgba(var(--bs-primary-rgb), 0.1);
				}

				&.active:before {
					position: absolute;
					content: '';
					width: 0.28rem;
					top: 0;
					left: 0;
					height: 100%;
					background-color: var(--bs-primary);
					border-radius: 0.5rem 0 0 0.5rem;
				}

				& i {
					transform: none !important;
				}


				& i[data-chevron] {
					display: none;
				}

				& span {
					width: auto;
					height: auto;
					opacity: 1;
					flex: 1 0;
					visibility: visible;
					text-align: start;
					padding-left: 1rem;
					white-space: normal !important;
					justify-content: start !important;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
					transition: all .25s ease-in-out;
				}

				&>i[data-chevron] {
					transition: transform .15s ease-in-out;
				}

			}
		}
	}
}

//DARK THEME
[data-bs-theme=dark] {
	& .sidenav-popover .popover-body .nav-item .nav-link:hover {
		background-color: var(--bs-dark) !important;
	}
}