.topics {

	& .swiper {
		overflow: initial !important;
	}

	& .swiper-slide {
		height: auto;
		display: flex !important;

		&:hover {
			z-index: 3;

			& .card {
				transform: scale(1.05) translateY(0px);
				box-shadow: 0px 0.25rem 1rem 0px rgba(0, 0, 0, .075) !important;

			}
		}
	}

	& .badge:has(.mdi) {
		flex: 0 1 auto;
		display: flex;
		align-items: center;
		line-height: 1 !important;
		font-size: 0.6rem !important;
		padding: 0 var(--bs-badge-padding-x) 0 0 !important;
	}

	& .cardLow .card {
		box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075);
	}

	& .cardMedium .card {
		box-shadow: 0 0 0.5rem rgba(var(--bs-warning-rgb), 1);
	}

	& .cardBlink .card {
		-webkit-animation: blink 2s cubic-bezier(0, 0, 0.2, 1) infinite;
		-moz-animation: blink 2s cubic-bezier(0, 0, 0.2, 1) infinite;
		-ms-animation: blink 2s cubic-bezier(0, 0, 0.2, 1) infinite;
		-o-animation: blink 2s cubic-bezier(0, 0, 0.2, 1) infinite;
		animation: blink 2s cubic-bezier(0, 0, 0.2, 1) infinite;
	}

	& .cardHight .card {
		box-shadow: 0 0 0.5rem rgba(var(--bs-danger-rgb), 1);
	}

	& .card {
		transition: all 0.15s ease-in-out;


		& .card-footer .btn {
			color: var(--bs-white);
		}
	}

	.topic-details {

		& .overlay {
			position: fixed;
			top: 0;
			bottom: 0;
			z-index: 1040;
			width: 100%;
			background-color: rgba(var(--bs-white-rgb), 0.5);
			left: 50%;
			transform: translateX(-50%);
			will-change: opacity;
			-webkit-backdrop-filter: blur(3px);
			backdrop-filter: blur(3px);
			cursor: pointer;
		}

		& .card-wrap {
			z-index: 1041;
			position: absolute;
			width: 50vw;
			cursor: auto;
			left: 25%;
			top: 25%;
			bottom: 25%;
			right: 25%;

			//mobile
			@media (max-width: 768px) {
				width: 90vw;
				left: 5vw;
				right: 5vw;
				top: 15vh;
				bottom: 15vh;
			}
		}
	}

	//mobile
	@media (max-width: 768px) {
		.card-header {
			min-height: 50px;
		}

		& .card-text,
		& .card-title {
			font-size: 0.6rem;
			white-space: normal !important;
		}

		& .card-footer .btn {
			display: inline;
			text-align: center;
			font-size: 2vw;
			width: 100%;
			height: 100%;
			border: none;
		}
	}

	@media (max-width: 576px) {
		& .card-footer .btn {
			display: inline;
			font-size: 0.7rem;
			width: 100%;
			height: 100%;
			border: none;
			line-height: 1;
			margin-top: 4px;
			border-radius: 0.3rem;
		}
	}

	//large screen
	@media (min-width: 768px) {
		& .card-header {
			min-height: 60px;
		}

		& .card-text,
		& .card-title {
			font-size: 0.8rem;
		}
	}
}

@keyframes blink {
	0% {
		box-shadow: 0 0 0.5rem rgba(var(--bs-warning-rgb), 1);
	}

	50% {
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, .075);
	}

	100% {
		box-shadow: 0 0 0.5rem rgba(var(--bs-warning-rgb), 1);
	}
}

@-webkit-keyframes blink {
	0% {
		box-shadow: 0 0 0.5rem rgba(var(--bs-warning-rgb), 1);
	}

	50% {
		box-shadow: 0 0 0.5rem rgba(0, 0, 0, .075);
	}

	100% {
		box-shadow: 0 0 0.5rem rgba(var(--bs-warning-rgb), 1);
	}
}