$sizes: 10px, 14px, 56px, 64px, 72px, 84px, 92px;

.mdi {
	vertical-align: middle;
}

@each $size in $sizes {

	.mdi-#{$size}.mdi-set,
	.mdi-#{$size}.mdi:before {
		font-size: $size;
	}
}